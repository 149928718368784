/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Header from "./header"
import { Box } from "@chakra-ui/react"
// 🗺 Map
import Map from "./Map/Map"

// ⚙️ GUI
import SidebarIndicator from "./SidebarIndicator/SidebarIndicator"
import MenuDrawerRefactored from "./Menu/MenuDrawerRefactored"
import { useDispatch, useSelector } from "react-redux"
import { useDisclosure } from "@chakra-ui/hooks"
import { Transition } from "@headlessui/react"

const Layout = ({ children, location }) => {
  const sendActionToStore = useDispatch()
  const CURRENT_PATH = useSelector(state => state.currentPath)
  const MENU_INFO = useSelector(state => state.menu)

  useEffect(() => {
    if (location) {
      let code = location.pathname.split("/")[1]
      if (code !== "en") {
        sendActionToStore({
          type: "change/lang",
          payload: "",
        })
        sendActionToStore({
          type: "change/localeId",
          payload: "de_DE",
        })
      } else {
        sendActionToStore({
          type: "change/lang",
          payload: code,
        })
        sendActionToStore({
          type: "change/localeId",
          payload: "en_US",
        })
      }
      let path = location.pathname.split("/")
      path.splice(path.length - 1, 1)
      let previousPath = path.join("/")
      // push previous URL
      sendActionToStore({
        type: "change/previousPath",
        payload: previousPath,
      })

      // push new URL
      let newPath = path.join("/")
      sendActionToStore({
        type: "change/path",
        payload: location.pathname,
      })

      // set new translated Url
      sendActionToStore({
        type: "change/translatedUrl",
        payload: location.pathname,
      })
    }
  }, [location])
  const isSSR = typeof window === "undefined"

  // ⬜️ Blank Pages
  const blankPages = [
    "/projectsceneDesigner/",
    "projectsceneDesigner",
    "/projectsceneDesigner",
    "/auth",
    "/karte",
    "karte",
    "/karte/",
  ]

  function checkBlankPage(path) {
    if (blankPages.includes(path)) {
      return true
    } else {
      return false
    }
  }

  const isBlankPage = checkBlankPage(location?.pathname.toString())

  // 🚫🗺 Mapless Pages
  const pagesWithMaps = [
    "/zukunftsbild",
    "/zukunftsbild/",
    "/en/vision",
    "/en/vision/",
    "/mobilitaetsschwerpunkte",
    "/mobilitaetsschwerpunkte/",
    "/en/mobility-focus",
    "/en/mobility-focus/",
    "/uebersicht",
    "/uebersicht/",
    "/en/overview",
    "/en/overview/",
    "/en",
    "/en/",
  ]

  function checkMaplessPage(path) {
    let isMaplessPage = true

    for (let URLofPageWithMap of pagesWithMaps) {
      if (
        path &&
        (path === "/" || path === "/en" || path.includes(URLofPageWithMap))
      ) {
        isMaplessPage = false
        break
      }
    }

    return isMaplessPage
  }

  const isMaplessPage = checkMaplessPage(location?.pathname.toString())

  return !isBlankPage ? (
    <div id={`mode ${isMaplessPage ? "mapless" : ""}`}>
      {/* ⚙️ GUI*/}
      {/* <GUI /> */}
      <div id="MarkerContainer" className="fixed overflow-hidden inset-0"></div>
      {/* 🖼 UI*/}
      <div id="ui" className={`flex h-full w-screen font-sans`}>
        {/* <WYSIWYGImageOverlay /> */}
        <Header singlePage={isMaplessPage} className="z-10" />
        <Box d={["none", "none", "block"]}>
          {!isMaplessPage ? (
            <SidebarIndicator className="z-10 portrait:invisible Phone:invisible" />
          ) : (
            ""
          )}
        </Box>
        <main className="z-0  h-full w-full overflow-auto">{children}</main>
      </div>
      {/* 🗺 Map */}
      {!isSSR && !isMaplessPage ? <Map id="Map" /> : ""}
    </div>
  ) : (
    <div id="blank">{children}</div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
