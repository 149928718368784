import PropTypes from "prop-types"
import React from "react"
import TransitionLink from "../TansitionLink"
import { useDispatch, useSelector } from "react-redux"
import { moveCameraToLayer } from "../Animation/CameraAnimation"
import { moveCurrentPageOut } from "../Animation/PageTransition"
import isMobile from "../../tools/isMobile"
import { Button, Box } from "@chakra-ui/react"

const checkLayerZero = (currentPath, link, lang, homeSlug, currentIndex) => {
  const URLparts = currentPath.split("/")
  const LINKparts = link.split("/")
  if (lang === "de_DE") {
    return (
      URLparts.length === 3 &&
      LINKparts.length === 2 &&
      URLparts[1] === homeSlug &&
      currentIndex === 0
    )
  } else {
    return (
      URLparts.length === 4 &&
      LINKparts.length === 2 &&
      URLparts[2] === homeSlug &&
      currentIndex === 0
    )
  }
}

const checkLayerOne = (currentPath, link, lang, homeSlug, currentIndex) => {
  const URLparts = currentPath.split("/")
  const LINKparts = link.split("/")
  if (lang === "de_DE") {
    return (
      URLparts.length === 3 &&
      LINKparts.length === 2 &&
      URLparts[1] === LINKparts[1] &&
      currentIndex === 1
    )
  } else {
    return (
      URLparts.length === 4 &&
      LINKparts.length === 2 &&
      URLparts[2] === LINKparts[1] &&
      currentIndex === 1
    )
  }
}

const checkLayerTwo = (currentPath, link, lang, homeSlug, currentIndex) => {
  const URLparts = currentPath.split("/")
  const LINKparts = link.split("/")

  if (lang === "de_DE") {
    return (
      (URLparts.length === 4 || URLparts.length === 5) &&
      URLparts[3] === LINKparts[3] &&
      currentIndex === 2
    )
  } else {
    return (
      (URLparts.length === 5 || URLparts.length === 6) &&
      URLparts[4] === LINKparts[4] &&
      currentIndex === 2
    )
  }
}

const checkIfDotIsActive = (link, lang, homeSlug, currentIndex) => {
  if (typeof window !== `undefined`) {
    const currentPath = window.location.pathname
    if (currentPath === link || currentPath.slice(0, -1) === link) {
      return true
    } else {
      // Complex
      // IS LAYER 0 DE || EN
      if (checkLayerZero(currentPath, link, lang, homeSlug, currentIndex)) {
        return true
      }
      // IS LAYER 1 DE || EN
      if (checkLayerOne(currentPath, link, lang, homeSlug, currentIndex)) {
        return true
      }
      // IS LAYER 2 DE || EN
      if (checkLayerTwo(currentPath, link, lang, homeSlug, currentIndex)) {
        return true
      }
    }
    return
  }
}

const checkTargetAnimationDirection = (link, allPages) => {
  if (typeof window !== `undefined`) {
    const currentPath = window.location.pathname
    const currentPosition = allPages.indexOf(currentPath)
    const targetPosition = allPages.indexOf(link)
    if (currentPosition > targetPosition) {
      return "up"
    } else if (currentPosition < targetPosition) {
      return "down"
    } else {
      return "fade"
    }
  }
}

const checkCurrentAnimationDirection = (link, allPages) => {
  if (typeof window !== `undefined`) {
    const currentPath = window.location.pathname
    const currentPosition = allPages.indexOf(currentPath)
    const targetPosition = allPages.indexOf(link)
    if (currentPosition > targetPosition) {
      return "down"
    } else if (currentPosition < targetPosition) {
      return "up"
    } else {
      return "fade"
    }
  }
}

const Dot = props => {
  const sendActionToStore = useDispatch()

  if (checkIfDotIsActive(props.link, props.lang, props.homeSlug, props.index)) {
    return (
      <>
        <TransitionLink className="dotIndicators mt-1 h-dot w-dot">
          <Box
            d="inline-block"
            mt="8px"
            w="10px"
            h="10px"
            rounded="full"
            borderWidth="3px"
            borderColor="black"
            background="black"
          ></Box>
        </TransitionLink>
      </>
    )
  } else {
    return (
      <>
        <TransitionLink
          className="dotIndicators mt-1 h-dot w-dot"
          to={props.link}
          exit={{
            length: 1,
          }}
          entry={{
            delay: 0,
          }}
          state={{
            direction: checkTargetAnimationDirection(
              props.link,
              props.allPages
            ),
            schwerpunkt: props.schwerpunkt,
          }}
        >
          <Button
            p="0px"
            m="0px"
            w="10px"
            minWidth="10px"
            variant="link"
            onClick={() => {
              switch (props.index) {
                case -1:
                  isMobile
                    ? moveCameraToLayer(sendActionToStore, "karte")
                    : moveCameraToLayer(sendActionToStore, "karteMobile")
                  break
                case 0:
                  moveCameraToLayer(sendActionToStore, "intro")
                  break
                case 1:
                  moveCameraToLayer(sendActionToStore, "schwerpunkte")
                  break
                case 2:
                  moveCameraToLayer(sendActionToStore, "projektziele")
                  break
                default:
                  break
              }
              moveCurrentPageOut(
                sendActionToStore,
                checkCurrentAnimationDirection(props.link, props.allPages)
              )
            }}
          >
            <Box
              d="inline-block"
              mt="8px"
              w="10px"
              h="10px"
              rounded="full"
              borderWidth="3px"
              borderColor="black"
              background="transition"
              transition="all"
              transitionDuration="300ms"
              _hover={{ background: "black" }}
            ></Box>
          </Button>
        </TransitionLink>
      </>
    )
  }
}

Dot.propTypes = {
  active: PropTypes.bool,
}

Dot.defaultProps = {
  active: false,
}

export default Dot
