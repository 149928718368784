import { createStore as reduxCreateStore, applyMiddleware } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import { translateUrl } from "../tools/translateUrl"
// 🥚 INITIAL STATE
const initialState = {
  camera: {
    position: {
      x: 18,
      y: 35,
      z: 30,
      zoom: 15,
      targetX: 0,
      targetY: 0,
      targetZ: 0,
    },
    mouseControlEnabled: false,
  },
  map: {
    groundColor: "#edf2f7",
  },
  projectSceneDesigner: {
    lastPosition: {
      x: 0,
      y: 0,
    },
  },
  activeLocation: {
    x: 0,
    y: 0,
  },
  mapMarkers: [],
  previewMarker: [],
  locationsActive: false,
  projectScene: {
    topVisible: false,
    bottomVisible: false,
  },
  markerContainerPortal: undefined,
  animation: {
    triggerUpdateCameraPosition: false,
    isCameraAnimationRunning: false,
    toPosition: {
      x: 25,
      y: 40,
      z: 25,
      zoom: 15,
      targetX: 0,
      targetY: 0,
      targetZ: 0,
    },
  },
  menu: {
    menuIsOpen: false,
    selectedTab: 0,
  },
  transition: {
    direction: null,
    shouldExit: false,
  },
  schwerpunktSlider: {
    prev: 0,
    current: 0,
  },
  projektzieleSlider: {
    prev: 0,
    current: 0,
  },
  imageOverlay: {
    isOpen: false,
    selectedMediaInfo: null,
  },
  currentLang: "",
  currentPath: "",
  translatedUrl: "",
  translatedSlug: "",
  previousPath: "",
  accessToken: "",
  lastResearchFilter: [],
  lastResearchSortBy: {},
  lastResearchSearch: "",
  currentLocaleID: "de_DE",
}

// ⚙️ DEFINE Reducer & Actions
const StoreReducer = (state, action) => {
  switch (action.type) {
    case "change/camera/mouseControlEnabled":
      return {
        ...state,
        camera: {
          ...state.camera,
          mouseControlEnabled: action.payload,
        },
      }

    case "change/map/groundColor":
      return {
        ...state,
        map: { groundColor: action.payload },
      }

    case "change/map/marker":
      console.log("STORE: change markers")
      return {
        ...state,
        mapMarkers: action.payload,
      }

    case "change/locationsActive":
      return {
        ...state,
        locationsActive: action.payload,
      }

    case "change/markerContainerPortal":
      return {
        ...state,
        markerContainerPortal: action.payload,
      }

    case "update/cameraPosition":
      return {
        ...state,
        camera: {
          ...state.camera,
          position: action.payload,
        },
      }

    case "animateCameraToPosition":
      return {
        ...state,
        animation: {
          ...state.animation,
          toPosition: action.payload,
        },
      }

    case "animation/isCameraAnimationRunning":
      return {
        ...state,
        animation: {
          ...state.animation,
          isCameraAnimationRunning: action.payload,
        },
      }

    case "animation/triggerUpdateCameraPosition":
      return {
        ...state,
        animation: {
          ...state.animation,
          triggerUpdateCameraPosition: action.payload,
        },
      }

    case "menu/openMenu":
      return {
        ...state,
        menu: {
          menuIsOpen: action.payload.menuIsOpen,
          selectedTab: action.payload.selectedTab,
        },
      }

    case "PageChangeTransition/start":
      return {
        ...state,
        transition: {
          direction: action.payload.direction,
          shouldExit: true,
        },
      }

    case "PageChangeTransition/ended":
      return {
        ...state,
        transition: {
          direction: null,
          shouldExit: true,
        },
      }

    case "schwerpunktSlider/change":
      return {
        ...state,
        schwerpunktSlider: {
          ...state.schwerpunktSlider,
          prev: state.schwerpunktSlider.current,
          current: action.payload.current,
        },
      }

    case "projektzieleSlider/change":
      return {
        ...state,
        projektzieleSlider: {
          ...state.projektzieleSlider,
          prev: state.projektzieleSlider.current,
          current: action.payload.current,
        },
      }

    case "change/lang":
      return {
        ...state,
        currentLang: action.payload,
      }
    case "change/localeId":
      return {
        ...state,
        currentLocaleID: action.payload,
      }

    case "change/path":
      return {
        ...state,
        currentPath: action.payload,
      }
    case "change/translatedUrl":
      let translatedUrl = translateUrl(
        action.payload,
        state.currentLocaleID,
        state.translatedSlug
      )
      return {
        ...state,
        translatedUrl: translatedUrl,
      }
    case "change/translatedSlug":
      return {
        ...state,
        translatedSlug: action.payload,
      }
    case "change/previousPath":
      return {
        ...state,
        previousPath: action.payload,
      }

    case "projectSceneDesigner/updatePosition":
      return {
        ...state,
        projectSceneDesigner: {
          lastPosition: action.payload,
        },
      }

    case "projectSceneDesigner/setPreviewMarker":
      return {
        ...state,
        previewMarker: action.payload,
      }

    case "change/projectScene":
      console.log("STORE: projectScene", action.payload)
      return {
        ...state,
        projectScene: action.payload,
      }

    case "change/activeLocation":
      return {
        ...state,
        activeLocation: action.payload,
      }

    case "projektziel/imageOverlay":
      return {
        ...state,
        imageOverlay: {
          isOpen: action.payload.isOpen,
          selectedMediaInfo: action.payload.selectedMediaInfo,
        },
      }

    case "auth/accessToken":
      return {
        ...state,
        accessToken: action.payload,
      }

    case "update/lastResearchFilter":
      return {
        ...state,
        lastResearchFilter: action.payload.lastResearchFilter,
        lastResearchSortBy: action.payload.lastResearchSortBy,
        lastResearchSearch: action.payload.lastResearchSearch,
      }

    default:
      return state
  }
}

// 🗄 CREATE STORE WITH REDUCER
const createStore = () =>
  reduxCreateStore(
    StoreReducer,
    initialState,
    composeWithDevTools(applyMiddleware())
  )
export default createStore
