import { extendTheme } from "@chakra-ui/react"
import { createBreakpoints } from "@chakra-ui/theme-tools"

const fonts = {
  body: "Inter, sans-serif",
  heading: "Inter, sans-serif",
  mono: "Inter, sans-serif",
  variable: "Inter var, sans-serif",
}

const fontWeights = {
  bold: 600,
}

const fontSizes = {
  sTiny: "0.875rem", // 14
  s0: "1rem", // 16
  s1: "1.125rem", // 18
  body: "1.125rem", // 18
  s2: "1.25", // 20
  s3: "1.5rem", // 24
  s4: "2.25rem", // 36
  s5: "4rem", // 64
}

const space = {
  0: "0",
  px: 1,
  0.5: "4px",
  1: "8px",
  dot: "10px",
  2: "12px",
  3: "16px",
  4: "24px",
  5: "32px",
  6: "48px",
  7: "64px",
  8: "96px",
  8.5: "120px",
  9: "160px",
  9.5: "230px",
  11: "390px",
  10: "320px",
  imageGal: "85%",
  teaser: "464px",
}

// ALL THE SAME Mode Gray Alternative
// Jedoch sieht man dann nicht mehr was Klickbar ist, was nicht
// 50: "#E3E6EA",
//   100: "#C6CCD5",
//   200: "#AAB3C0",
//   300: "#8D99AB",
//   400: "#718096",
//   500: "#718096",
//   600: "#4A5568",
//   700: "#3F4858",
//   800: "#444D5A",
//   900: "#2D333C",

// OLD ONE
// 50: "#F2F2F2",
// 100: "#DBDBDB",
// 200: "#C4C4C4",
// 300: "#ADADAD",
// 400: "#969696",
// 500: "#808080",
// 600: "#666666",
// 700: "#4D4D4D",
// 800: "#333333",
// 900: "#18181B",

const colors = {
  current: "currentColor",
  modeRed: {
    50: "#FCEBE8",
    100: "#F8C7BF",
    200: "#F3A296",
    300: "#EE7E6D",
    400: "#EC6C58",
    500: "#E95740",
    600: "#E24531",
    700: "#B72B15",
    800: "#5B160B",
    900: "#2E0B05",
  },
  modeGray: {
    50: "#E2E8F0",
    100: "#E3E6EA",
    200: "#C6CCD5",
    300: "#AAB3C0",
    400: "#8D99AB",
    500: "#718096",
    600: "#4A5568",
    700: "#3F4858",
    800: "#444D5A",
    900: "#2D333C",
  },
  buttonPrimary: {
    500: "#E53E3E",
    600: "#C53030",
    700: "#B62D2D",
  },
  buttonSecondary: {
    500: "#718096",
    600: "#4A5568",
    700: "#3F4858",
  },
  buttonTertiary: {
    100: "#E2E8F0",
    500: "#E2E8F0",
    600: "#CDD5E0",
    700: "#3F4858",
    800: "#AEB7C3",
  },
}

const shadows = {
  subtle: "0px 0px 6px 4px rgba(0,0,0,.1)",
}

// portrait: { raw: "(orientation: portrait)" },
// Desktop: { max: "1920px" }, // iPhone 8 & X
// Tablet: { max: "1024px" }, // iPhone 8 & X
// TabletPortrait: { max: "768px" }, // iPhone 8 & X
// Phone: { max: "428px" }, // iPhone 8 & X
// SmallPhone: { max: "320px" }, // iPhone SE

// const breakpoints = createBreakpoints({
//   Desktop: "30em",
//   Tablet: "48em",
//   TabletPortrait: "62em",
//   Phone: "80em",
//   SmallPhone: "96em",
// })

const theme = extendTheme({
  fonts,
  fontWeights,
  fontSizes,
  colors,
  space,
  shadows,
  components: {
    Menu: {
      baseStyle: {
        list: {
          "&:focus:not([data-focus-visible-added])": {
            shadow: "lg",
            boxShadow: "var(--chakra-shadows-lg) !important",
          },
          "&:focus:not(.focus-visible)": {
            shadow: "lg",
            boxShadow: "var(--chakra-shadows-lg) !important",
          },
        },
      },
    },
  },
})

export default theme
