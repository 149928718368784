import React from "react"
import { Link as GatsbyLink } from "gatsby"

export const Link = props => {
  // TODO Fix External Link Problem
  /** Check if External Link and then take Path instead */
  // console.log("🔗 LINK!!!!! ", props.to)
  // const regex = /(http[s]?:\/\/)?([^\/\s]+\/)(.*)/
  // let url = props.to
  // let res = regex.exec(url)
  // if (res !== null && res[1] !== undefined) {
  //   console.log(regex.exec(url))
  //   url = res[3]
  //   console.log("url", url)
  // }
  // console.log("🔗 LINK DONE!!!!! ", url)
  return (
    <GatsbyLink {...props} className={props.className}>
      {props.children}
    </GatsbyLink>
  )
}
