import * as React from "react"
import {
  HStack,
  Badge,
  Box,
  Heading,
  Input,
  Text,
  VStack,
  Skeleton,
  Stack,
  IconButton,
  Flex,
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuButton,
  background,
} from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { Link } from "../Link"
import { graphql, useStaticQuery } from "gatsby"
import { useSelector } from "react-redux"
import IconSearch from "../Icons/IconSearch"
import GenUrl from "../../hooks/useGenUrl"

const Search = props => {
  const CURRENT_LANG = useSelector(state => state.currentLang)
  const [searchResults, setSearchResults] = useState("")
  const [buttonIsLoaded, setButtonIsLoaded] = useState(true)
  const [searchedOnce, setSearchedOnce] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [searchInput, setSearchInput] = useState("")
  const [searchInputStatic, setSearchInputStatic] = useState("")
  const [error, setError] = useState()
  const [entryCount, setEntryCount] = useState([0, 0, 0, 0])
  const [totalCount, setTotalCount] = useState(0)
  const [activeFilter, setActiveFilter] = useState([false, false, false, false])
  const [anyFilterActive, setAnyFilterActive] = useState(false)
  const CURRENT_LOCALE = useSelector(state => state.currentLocaleID)
  const data = useStaticQuery(graphql`
    query SearchQuery {
      de_DE: wpModeSettings(
        slug: { eq: "suche" }
        locale: { locale: { eq: "de_DE" } }
      ) {
        acf_search_settings {
          textSearchIntrotext
          textSearchResultfoundSingle
          textSearchResultsfoundMultiple
          textSearchResultsnone
          textSearchTitle
          textSerachPlaceholder
        }
      }
      en_EN: wpModeSettings(
        slug: { eq: "suche" }
        locale: { locale: { eq: "en_US" } }
      ) {
        acf_search_settings {
          textSearchIntrotext
          textSearchResultfoundSingle
          textSearchResultsfoundMultiple
          textSearchResultsnone
          textSearchTitle
          textSerachPlaceholder
        }
      }
    }
  `)

  const search_DE = data.de_DE.acf_search_settings
  const search_EN = data.en_EN.acf_search_settings

  // * Search Settings - DE
  const search_title =
    CURRENT_LANG === "en"
      ? search_EN.textSearchTitle
      : search_DE.textSearchTitle
  const search_introText =
    CURRENT_LANG === "en"
      ? search_EN.textSearchIntrotext
      : search_DE.textSearchIntrotext
  const search_placeholder =
    CURRENT_LANG === "en"
      ? search_EN.textSerachPlaceholder
      : search_DE.textSerachPlaceholder
  const search_resultsFoundSingle =
    CURRENT_LANG === "en"
      ? search_EN.textSearchResultfoundSingle
      : search_DE.textSearchResultfoundSingle
  const search_resultsFoundMultiple =
    CURRENT_LANG === "en"
      ? search_EN.textSearchResultsfoundMultiple
      : search_DE.textSearchResultsfoundMultiple
  const search_resultsNone =
    CURRENT_LANG === "en"
      ? search_EN.textSearchResultsNone
      : search_DE.textSearchResultsNone

  // * Search Settings - EN

  const search_filterNames = [
    "Projektziele",
    "Wissenschaft",
    "Vokabulareinträge",
    "Seiten",
  ]

  const search_filterNames_en = [
    "Project Goals",
    "Research",
    "Vocabulary Entries",
    "Pages",
  ]

  const search_badgeNames = [
    "Projektziel",
    "Wissenschaft",
    "Vokabular",
    "Seite",
  ]
  const search_badgeNames_en = [
    "Project Goal",
    "Research",
    "Vocabulary",
    "Page",
  ]

  function validateSearchResults(result) {
    // console.log(result)
    if (result.length > 0) {
      // Count Entries
      let currentCount = [0, 0, 0, 0]
      result.map((item, i) => {
        switch (item.type) {
          case "projektziele":
            currentCount[0]++
            break
          case "forschung":
            currentCount[1]++
            break
          case "lexikon":
            currentCount[2]++
            break
          case "mode_settings":
            break
          case "map_icon":
            break
          default:
            currentCount[3]++
        }
        // console.log(currentCount)
        return 0
      })
      setEntryCount(currentCount)
      let tC =
        currentCount[0] + currentCount[1] + currentCount[2] + currentCount[3]
      setTotalCount(tC)
      setSearchResults(result)
    } else {
      setEntryCount([0, 0, 0])
      setSearchResults("")
    }
    setIsLoaded(true)
    setButtonIsLoaded(true)
  }

  function handleSubmit(e) {
    e.preventDefault()
    if (!searchedOnce) {
      setSearchedOnce(true)
    }
    if (searchInput !== "" && searchInput !== " ") {
      setSearchResults("")
      setIsLoaded(false)
      setSearchInputStatic(searchInput)
      setButtonIsLoaded(false)
      fetch(
        CURRENT_LANG === "en"
          ? `${process.env.GATSBY_CMS_URL}/wp-json/wp_query/args/?s=${searchInput}&lang=en&_embed`
          : `${process.env.GATSBY_CMS_URL}/wp-json/wp_query/args/?s=${searchInput}&_embed`
      )
        // .then(console.log("Searching for: " + searchInput))
        .then(res => res.json())
        .then(
          result => {
            validateSearchResults(result)
          },
          error => {
            setError(error)
            setIsLoaded(false)
            setButtonIsLoaded(true)
          }
        )
    } else {
      setSearchResults("")
      setIsLoaded(true)
      setSearchInputStatic(CURRENT_LANG === "en" ? `(empty)` : `(leer)`)
      validateSearchResults([])
    }
  }

  function handleInputChange(e) {
    setSearchInput(e.target.value)
  }

  function handleFilterChange(filterIndex) {
    const oldState = activeFilter
    oldState[filterIndex] = !oldState[filterIndex]
    setActiveFilter(oldState)

    if (
      activeFilter[0] ||
      activeFilter[1] ||
      activeFilter[2] ||
      activeFilter[3]
    ) {
      setAnyFilterActive(true)
    } else {
      setAnyFilterActive(false)
    }

    validateSearchResults(searchResults)
  }

  useEffect(() => {
    //
  }, [])

  function getColorByFilter(i) {
    switch (i) {
      case 0:
        return "blue"
      case 1:
        return "yellow"
      case 2:
        return "red"
      case 3:
        return "green"
      default:
        return "gray"
    }
  }

  const CustomBadge = props => {
    if (props.index === undefined) {
      return <></>
    }

    const label =
      CURRENT_LANG === "en"
        ? search_filterNames_en[props.index]
        : search_filterNames[props.index]
    const c = getColorByFilter(props.index)

    return (
      <Button
        minW="fit-content"
        w={props.responsive ? "full" : "unset"}
        rounded="lg"
        p="2"
        style={
          activeFilter[props.index] === true
            ? { border: "1px solid transparent" }
            : {
                borderColor: "gray.100",
                borderWidth: "1px",
              }
        }
        background={
          activeFilter[props.index] === true ? "buttonTertiary.500" : "white"
        }
        _hover={{
          background: "buttonTertiary.600",
        }}
        onClick={() => handleFilterChange(props.index)}
        disabled={entryCount[props.index] === 0}
      >
        {label}{" "}
        {entryCount[props.index] > 0 ? `(${entryCount[props.index]})` : ""}
      </Button>
    )
  }

  function checkCategory(i) {
    // return true if category is shown, false if not
    let logic = !anyFilterActive || (anyFilterActive && activeFilter[i])

    return (
      (!anyFilterActive || (anyFilterActive && activeFilter[i])) &&
      entryCount[i] > 0
    )
  }

  function trimExcerpt(excerpt) {
    let wordLocation = excerpt.search(searchInput)
    if (wordLocation !== -1) {
      return excerpt.substring(wordLocation - 60, wordLocation + 60)
    }
  }

  function stripHtml(html) {
    let tmp = document.createElement("DIV")
    tmp.innerHTML = html
    return tmp.textContent || tmp.innerText || ""
  }

  const ListSearchResult = itemProp => {
    const { item, i } = itemProp
    let t = " "
    let typeToFilterIndex = -1

    let c = "gray"
    let path = CURRENT_LANG === "en" ? "/en/" : "/"

    // console.log("Result type", item.type)

    switch (item.type) {
      case "projektziele":
        t = item.acf.group_left.text_summary
        typeToFilterIndex = 0
        path = GenUrl(
          item.slug,
          "projektziel",
          CURRENT_LOCALE,
          item.acf.group_left.relation_mobilitaetsschwerpunkt?.post_name
        )
        // `/mobilitaetsschwerpunkte/${item.acf.group_left.relation_mobilitaetsschwerpunkt.post_name}/projektziele/${item.slug}`
        break
      case "forschung":
        t = item.acf.projektbeschreibung.text_projektbeschreibung_text
        path = `/wissenschaft/${item.slug}`
        typeToFilterIndex = 1
        break
      case "lexikon":
        if (item.content !== undefined) {
          t = item.content.rendered
        }
        typeToFilterIndex = 2
        path += `?menu=2&vokabular=${item.title.rendered}`
        break
      case "page":
        if (item.content !== undefined) {
          t = item.content.rendered
        }
        typeToFilterIndex = 3
        path += `${item.slug}`
        break
      case "schwerpunkte":
        if (item.acf.text_schwerpunkt_teaser_text !== undefined) {
          t = item.acf.text_schwerpunkt_teaser_text
        }
        typeToFilterIndex = 3
        path +=
          CURRENT_LANG === "en"
            ? `mobility-focus/${item.slug}`
            : `mobilitaetsschwerpunkte/${item.slug}`
        break
      default:
        typeToFilterIndex = 3
        break
    }

    c = getColorByFilter(typeToFilterIndex)

    if (!checkCategory(typeToFilterIndex)) {
      return <Box position="absolute" />
    }

    if (item.type === "mode_settings" || item.type === "map_icon") {
      return <Box position="absolute" />
    }

    return (
      <Link
        to={item !== undefined ? path : "/"}
        onClick={
          item.type !== "lexikon"
            ? () => props.closeMenu()
            : () => props.openLexicon()
        }
      >
        <Box
          w="100%"
          p="2"
          border="1px solid"
          borderColor="gray.100"
          rounded="lg"
          position="relative"
          _hover={{
            transform: "translateY(-6px)",
            boxShadow: "lg",
            transition: "all 0.2s",
          }}
          transition="all 0.1s ease-out"
        >
          <Heading fontSize="xl" fontWeight="600">
            <span style={{ marginRight: "4px" }}>{"->"}</span>
            {item.title !== undefined ? item.title.rendered : "undefined"}
          </Heading>
          <Flex mt="2" w="full">
            <Badge rounded="xl" p="1" background="buttonTertiary.500" h="32px">
              {CURRENT_LANG === "en"
                ? search_badgeNames_en[typeToFilterIndex]
                : search_badgeNames[typeToFilterIndex]}
            </Badge>
            <Text pl="2" flex="1" isTruncated lineHeight="32px">
              {stripHtml(t)}
            </Text>
          </Flex>
        </Box>
      </Link>
    )
  }

  let noResultsFound = <b>{searchInputStatic}</b>

  return (
    <Box>
      <Heading mt={{ base: "6", md: "8" }}>{search_title}</Heading>
      <Text mt="4">{search_introText}</Text>
      <form onSubmit={e => handleSubmit(e)}>
        <HStack mt="6">
          <Input
            rounded="md"
            type="text"
            size="lg"
            fontSize="xl"
            placeholder={search_placeholder}
            value={searchInput}
            onChange={handleInputChange}
          />
          <IconButton
            colorScheme="gray"
            isLoading={!buttonIsLoaded}
            borderRadius="full"
            padding="0px"
            aria-label="Suche / Search"
            icon={<IconSearch className="w-5" />}
            type="submit"
            value="Submit"
          />
        </HStack>
      </form>
      <Box display={{ base: "none", md: "inherit" }}>
        <Text mt="4" mb="1" fontSize="sm" fontWeight="bold">
          {CURRENT_LANG === "en" ? "Filter by" : "Filtern nach"}
        </Text>
        <Stack
          maxW="100%"
          direction={["column", "row"]}
          spacing="2"
          wrap="wrap"
        >
          <CustomBadge aF={activeFilter} index={0} />
          <CustomBadge aF={activeFilter} index={1} />
          <CustomBadge aF={activeFilter} index={2} />
          <CustomBadge aF={activeFilter} index={3} />
        </Stack>
      </Box>
      <Box display={{ md: "none" }}>
        <Menu>
          <MenuButton
            mt="2"
            p="8px"
            background="buttonTertiary.500"
            rounded="lg"
            _active={{ background: "buttonTertiary.600" }}
            fontSize="sm"
            fontWeight="bold"
          >
            {CURRENT_LANG === "en" ? "Filter by" : "Filtern nach"}
          </MenuButton>
          <MenuList p="0" shadow="lg">
            <MenuItem>
              <CustomBadge responsive aF={activeFilter} index={0} />
            </MenuItem>
            <MenuItem>
              <CustomBadge responsive aF={activeFilter} index={1} />
            </MenuItem>
            <MenuItem>
              <CustomBadge responsive aF={activeFilter} index={2} />
            </MenuItem>
            <MenuItem>
              <CustomBadge responsive aF={activeFilter} index={3} />
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
      <Box mt="4">
        {searchResults !== "" && searchResults !== undefined ? (
          <>
            <Text fontWeight="bold" fontSize="lg">
              {totalCount === 1
                ? totalCount === 0
                  ? search_resultsNone
                  : `1 ${search_resultsFoundSingle}`
                : `${totalCount} ${search_resultsFoundMultiple}`}
            </Text>
            <VStack mt="4" align="left" transition="all 0.2s">
              {searchResults.map((item, i) => (
                <ListSearchResult key={`projektziele_${i}`} item={item} />
              ))}
            </VStack>
          </>
        ) : searchedOnce ? (
          <Text display={isLoaded ? "block" : "none"}>
            {CURRENT_LANG === "en" ? (
              <>
                No results for <b>{searchInputStatic}</b> found.
              </>
            ) : (
              <>
                Keine Ergebnisse für <b>{searchInputStatic}</b> gefunden.
              </>
            )}
          </Text>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  )
}

export default Search
