import React from "react"
import OriginalTransitionLink from "gatsby-plugin-transition-link/AniLink"

const TransitionLink = props => {
  // TODO Fix External Link Problem
  /** Check if External Link and then take Path instead */
  // console.log("🔗 TL LINK!!!!! ", props.to)
  // const regex = /(http[s]?:\/\/)?([^\/\s]+\/)(.*)/
  // let url = props.to
  // let res = regex.exec(url)
  // if (res !== null && res[1] !== undefined) {
  //   console.log(regex.exec(url))
  //   url = res[3]
  //   console.log("url", url)
  //   props.to = url
  // }
  // console.log("🔗 TL LINK DONE!!!!! ", url)
  return (
    <OriginalTransitionLink {...props}>{props.children}</OriginalTransitionLink>
  )
}

export default TransitionLink
