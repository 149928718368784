import * as React from "react"
import { Link } from "../Link"
import { useStaticQuery } from "gatsby"
import { graphql } from "gatsby"
import { Box, Text, VStack, Flex, Heading, Button } from "@chakra-ui/react"
import { useSelector } from "react-redux"

const Navigation = props => {
  const CURRENT_LOCALE = useSelector(state => state.currentLocaleID)

  const data = useStaticQuery(graphql`
    query NavigationQuery {
      wp {
        generalSettings {
          url
        }
      }
      wpModeSettings(slug: { eq: "global" }, locale: { id: { eq: "de_DE" } }) {
        acf_global_settings {
          englishAvailable
        }
      }
      de_DE: wpModeSettings(
        slug: { eq: "navigation" }
        locale: { id: { eq: "de_DE" } }
      ) {
        acf_navigation {
          repeaterMenuItems {
            urlLinkMenu
            linktext
          }
          repeaterFooterItems {
            urlLinkFooter
            linktext
          }
          textKurzbeschreibung
        }
      }
      en_US: wpModeSettings(
        slug: { eq: "navigation" }
        locale: { id: { eq: "en_US" } }
      ) {
        acf_navigation {
          repeaterMenuItems {
            urlLinkMenu
            linktext
          }
          repeaterFooterItems {
            urlLinkFooter
            linktext
          }
          textKurzbeschreibung
        }
      }
    }
  `)

  const menu = data[CURRENT_LOCALE].acf_navigation
  const metadata = data.wp
  const settings = data.wpModeSettings

  const CURRENT_LANG = useSelector(state => state.currentLang)

  return (
    <Box>
      <Text mt={{ base: "6", md: "8" }}>{menu.textKurzbeschreibung}</Text>
      <VStack mt={{ base: "6", md: "8" }} spacing="4" align="left">
        {menu.repeaterMenuItems.length > 0 &&
          menu.repeaterMenuItems.map((i, index) => (
            <Link to={i.urlLinkMenu} onClick={props.closeMenu} key={index}>
              <Flex _hover={{}}>
                <Heading
                  fontSize={{ base: "2xl", md: "3xl" }}
                  rounded="3xl"
                  // ml="16px"
                  p="2"
                  pl="0"
                  transition="all 250ms"
                  className="menuLink"
                >
                  {i.linktext}
                </Heading>
              </Flex>
            </Link>
          ))}
      </VStack>
      <VStack mt={{ base: "6", md: "8" }} align="left">
        {menu.repeaterFooterItems.length > 0 &&
          menu.repeaterFooterItems.map((i, index) => (
            <Box className="menuLink  " key={index}>
              <Link to={i.urlLinkFooter} onClick={props.closeMenu}>
                {i.linktext}
              </Link>
            </Box>
          ))}
      </VStack>
      <p>{metadata.generalSettings.url}</p>
    </Box>
  )
}

export default Navigation
